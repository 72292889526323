import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    Form,
    Button,
    Divider,
    Segment,
    Label,
    Header,
    Icon,
    Grid
} from 'semantic-ui-react';

import { 
    alertService, 
    providerService,
    facilityService,
    customerService
} from '../_services';

import { 
    providerRoleOptions
} from '../models/Constants';

import { Link } from 'react-router-dom';

function AddEdit({ history, match }) {
    const { id } = match.params;
    const [isAddMode,setIsAddMode] = useState(!id);
    const pathname = window.location.pathname;
    const [isLoading, setLoading] = useState(false);
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState({});
    const [provider, setProvider] = useState({});
    const [currentProviderFacilities, setCurrentProviderFacilities] = useState([]);
    const [providerFacilitiesToUpdate, setProviderFacilitiesToUpdate] = useState([]);
    const [providerFacilitiesToUnassign, setProviderFacilitiesToUnassign] = useState([]); 

    // View mode
    const [viewMode, setViewMode] = useState(false);

    // form validation rules
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('First Name is required'),
        last_name: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required'),
        phone_number: Yup.string()
            .max(12, 'Phone number must be no more than 12 digits long')
            .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx')
            .required('Phone Number is required'),
        external_user_id: Yup.string()
            .min(10, 'Must be exactly 10 digits')
            .max(10, 'Must be exactly 10 digits')
            .required('NPI is required'),
        role_name: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            external_user_id: "",
            facility:"",
            facilities: [],
            facilities_to_assign:[],
            facilities_to_unassign:[],
            // Role is optional and initially defaults to provider
            role_name: "PRESCRIBING_PROVIDER",
            is_primary: false
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    // functions to build form returned by useForm() hook
    const { setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        // Strip dashes from numbers
        data["phone_number"] = data["phone_number"].replace(/-/g, "");

        return isAddMode
            ? createProvider(data)
            : updateProvider(id, data);
    }

    function createProvider(data) {
        delete data["is_primary"];
        delete data["facility"];
        delete data["facility_id"];
        return providerService.create(data)
            .then((response) => {
                alertService.success('Provider added', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function updateProvider(id, data) {
        
        delete data["role_name"];
        delete data["is_primary"];
        delete data["facilities"];
        delete data["facility"];

        data.facilities_to_assign = providerFacilitiesToUpdate;

        return providerService.update(id, data)
            .then((response) => {
                alertService.success('Provider updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function handleResendLoginInfo() {
        const provider_email = formik.values.email;
        return customerService.resendLoginInfo({ user_email: provider_email }).then((response) => {
            alertService.success('Login Info resent', { keepAfterRouteChange: true });
            history.push('..');
        })
        .catch((err) => {
            alertService.error(err);
        });
    } 

    const handleOptionChange = (key, options) => {
        // Find the selected option from he dropdown
        const siteOption = options.find(function(site) {
            return site.key === key
        });

        // See if the option exists in currentProviderFacilities
        const siteToAdd = currentProviderFacilities.find(function(site){
            return site.id === key
        });

        // Check for Dupes
        if (!currentProviderFacilities.includes(siteToAdd)){
            
            currentProviderFacilities.push({
                "external_facility_id": siteOption.data.external_id,
                "id" : key,
                "is_active": siteOption.data.is_active,
                "is_primary":false,
                "name" : siteOption.data.site_name
            });
            setCurrentProviderFacilities(currentProviderFacilities);

            if (isAddMode) {
                formik.setFieldValue("facility_id", key);
                formik.values.facilities.push({"id": key.toString(), "is_primary": false})
            }
        
            if (!isAddMode){
                providerFacilitiesToUpdate.push({"id": key.toString(), "is_primary": false});
                formik.setFieldValue("facilities_to_assign", providerFacilitiesToUpdate);
            }
        }

        if (providerFacilitiesToUnassign.includes(siteToAdd)){
            const listWithRemovedFacility = providerFacilitiesToUnassign.filter(function( fac ) {
                return fac.id !== siteToAdd.id;
              });
        
            setProviderFacilitiesToUnassign(listWithRemovedFacility);
        
            formik.setFieldValue("facilities_to_unassign", providerFacilitiesToUnassign);
        }
        
    }
    
   

    const unAssignSite = (facility) => {
        
        const listWithRemovedFacility = currentProviderFacilities.filter(function( fac ) {
            return fac.id !== facility.id;
          });
        
        setCurrentProviderFacilities(listWithRemovedFacility);
        
        if(isAddMode) {
            // If Add Mode we want to not include removed facilities in the creation
            if (listWithRemovedFacility.length > 1){
                formik.setFieldValue('facilities', listWithRemovedFacility);
            }
            else{
                formik.setFieldValue('facilities', [])
            }
            currentProviderFacilities.forEach((fac) => formik.values.facilities.push({"id": fac.id, "is_primary": fac.is_primary}));
        }
        
        providerFacilitiesToUnassign.push({"id": facility.id.toString(), "is_primary": facility.is_primary});

        formik.setFieldValue("facilities_to_unassign", providerFacilitiesToUnassign);
       
        // Remove the facilitiy from the facilities to assign list
        const removedItemFromAssignList = providerFacilitiesToUpdate.filter(function( fac ) {
            return fac.id !== facility.id;
        });

        formik.setFieldValue("facilities_to_assign", removedItemFromAssignList);

        
    }

    useEffect(() => {
        if (isAddMode) {
            facilityService.getSitesProviders()
            .then((_sites) => {
                if(_sites) {
                    // Form - Set Sites options
                    const siteOptions = [];
                    _sites.data.map((x, index) => {
                        
                        siteOptions.push(
                            {
                                key: x.site_id,
                                text: x.site_name,
                                value: x.site_id,
                                ind: index,
                                data: x
                            }
                        );
                    });
                    setSites(siteOptions);
                }
            })
            .catch((err) => {
                console.log("No sites found!");
            });
        }
    }, []);

    useEffect(() => {
        if (pathname.includes("view")) {
            setViewMode(true);
        } else {
            setViewMode(false);
        }
    }, [pathname]);


    // Loading wheel for edit/view
    useEffect(() => {
        if (window.onload || isAddMode) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [window]);

    useEffect(() => {
        if (!isAddMode) {
            facilityService.getSitesProviders()
            .then((_sites) => {
                if(_sites) {
                    // Form - Set Sites and Providers options
                    const siteOptions = [];
                    _sites.data.map((x, index) => {
                        siteOptions.push(
                            {
                                key: x.site_id,
                                text: x.site_name,
                                value: x.site_id,
                                ind: index,
                                data: x
                            }
                        );
                    });
                    setSites(siteOptions);
                }
                // get provider info and set form fields

                providerService.getById(id)
                .then(provider => {
                    setLoading(false);
                    const providerData = provider.Data[0];
                    setCurrentProviderFacilities(providerData.facilities);
                    formik.setFieldValue("facilities", providerData.facilities);
                    formik.setFieldValue("first_name", providerData.first_name);
                    formik.setFieldValue("last_name", providerData.last_name);
                    formik.setFieldValue("external_user_id", providerData.external_id);
                    formik.setFieldValue("email", providerData.email);
                    providerData.phone_number = providerData.phone_number.slice(0,3)+"-"+providerData.phone_number.slice(3,6)+"-"+providerData.phone_number.slice(6);
                    formik.setFieldValue("phone_number", providerData.phone_number);
                    formik.setFieldValue("role_name", "PRESCRIBING_PROVIDER");
                    formik.setFieldValue("is_primary", false);
                })
                .catch( err => {
                    console.log("Unable to get provider information, error: ", err);
                });
            })
        }
    }, []);
    
    return (
        
        <Form className="pl-5" loading={isLoading} onSubmit={formik.handleSubmit} style={{"height":"85vh"}}>
        <Header as='h3' style={{paddingTop:'20px', color:'#3F2A57'}}>Provider Details <Icon style={{paddingLeft: '10px', color:'#3F2A57'}} name='user md'/></Header>
            <div className="mt-4">
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Provider First Name"
                        placeholder="First Name"
                        name="first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.first_name}
                        value={formik.values.first_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Provider Last Name"
                        placeholder="Last Name"
                        name="last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.last_name}
                        value={formik.values.last_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="NPI"
                        placeholder="NPI (10 digits)"
                        name="external_user_id"
                        onChange={formik.handleChange}
                        error={formik.errors.external_user_id}
                        value={formik.values.external_user_id}
                        width={2}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input 
                        fluid
                        readOnly={viewMode}
                        label="Provider Email"
                        placeholder="Email"
                        name="email"
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        value={formik.values.email} 
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Provider Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.phone_number}
                        value={formik.values.phone_number}
                        width={3}
                    />
                </Form.Group>
                
                <Header as='h3' style={{paddingTop:'20px', color:'#3F2A57'}}>Assigned Sites <Icon style={{paddingLeft: '10px', color:'#3F2A57'}} name='hospital outline'/></Header>
                <div>
                    <Form.Group inline>
                    <Form.Select
                        fluid
                        selection
                        readOnly={viewMode} 
                        disabled={viewMode} 
                        selected={selectedSite}
                        placeholder="Add Site to Provider"
                        name="facility_id"
                        options={sites ? sites : []}
                        selectOnNavigation={false}
                        onChange={(event, data) => {handleOptionChange(data.value, data.options)}}
                        error={formik.errors.facility_id}
                        value={{label:selectedSite}} 
                        width={6}
                    />
                    </Form.Group>
                    <div>
                        {/* This is for add mode */}
                        {isAddMode && currentProviderFacilities && currentProviderFacilities.map(facility => 
                            <Label onClick={ (event, data) => !viewMode && unAssignSite(data.site)} 
                                key={facility.id}
                                site={facility} 
                                disabled={viewMode}
                                basic
                                value={facility.name} 
                                size={'large'} 
                                style={{color:'#3F2A57'}}>
                                    {facility.name} {!viewMode && <Icon style={{paddingLeft:'10px'}} name='delete' />}
                            </Label>)
                        }

                        {/* This is for edit mode */}
                        {!isAddMode && currentProviderFacilities && currentProviderFacilities.map(facility => 
                            <Label onClick={ (event, data) => !viewMode && unAssignSite(data.site)} 
                                key={facility.id}
                                site={facility} 
                                disabled={viewMode}
                                basic 
                                value={facility.name} 
                                size={'large'} 
                                style={{color:'#3F2A57'}}>
                                    {facility.name} {!viewMode && <Icon style={{paddingLeft:'10px'}} name='delete' />}
                            </Label>)
                        }
                    </div>  
                </div>
            </div>
                
            <div className="pt-5 mt-5 pl-2 mb-5 pb-5">
                <Form.Group>
                    <div className="pr-3">
                        <Button 
                            type="submit"
                            primary
                            onSubmit={formik.handleSubmit}
                            disabled={formik.isSubmitting || viewMode}  
                        >
                            {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Submit
                        </Button>
                    </div>
                    <Button secondary as={ Link } name='providers' to='/providers'>Cancel</Button>
                    <Button
                        className="ml-3"
                        type="button" 
                        name="resend_button"
                        disabled={!viewMode}
                        onClick={handleResendLoginInfo}
                        >Resend Login Info
                    </Button>
                </Form.Group>
            </div>
            
        </Form>
    );
}

export { AddEdit };