import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Icon, Table, Popup, Label } from 'semantic-ui-react';

import { providerService, alertService, patientService } from '../_services';
import SearchBar from '../_components/SearchBar';
import RecordsPerPageSelection from '../_components/RecordsPerPageSelection';

function List(props) {
    const { path } = props.match;
    const [selRecordsPerPage, setSelRecordsPerPage] = useState(sessionStorage.getItem("record_per_page"));
    const [providers, setProviders] = useState(null);
    const [state, setState] = useState({
        column: null,
        data: null,
        direction: null
    });
    const { column, data, direction } = state
    const [params, setParams] = useState({
        name: "",
        record_per_page: sessionStorage.getItem('record_per_page'),
        page_number: 0
    });

    const handleSearch = (searchValue) => {
        search(searchValue);
    }

    const handleSort = (event) => {
        if (column !== event.column) {
            setState({
                column: event.column,
                data: _.sortBy(event.data, [event.column]),
                direction: "ascending"
            });
            setProviders({
                data: _.sortBy(event.data.data, [event.column]),
                record_per_page: providers.record_per_page,
                page_number: providers.page_number,
                status_code: providers.status_code,
                total: providers.total 
            });
            return;
        }
        setState({
            column: event.column,
            data: providers.data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    const handlePagination = (page) => {
        providerService.getAll({
            name: "",
            record_per_page: sessionStorage.getItem('record_per_page'),
            page_number: Math.ceil(page)
        })
        .then(x => setProviders(x));
    }

    const search = (searchParam) => {
        providerService.getAll(searchParam)
        .then(x =>
            {
                setProviders(x);
            });
    };

    const handleRPP = (e, data) => {
        setSelRecordsPerPage(data.value);
        setParams( {
            name: params.name,  
            record_per_page: selRecordsPerPage, 
            page_number: params.page_number,
            status_id: params.status_id
          });
    };
    useEffect(() => {
        handlePagination(params.page_number);
    }, [selRecordsPerPage]);
        
    useEffect(() => { providerService
        providerService.getAll(params)
        .then(x =>
            {
                setProviders(x);
            });
    }, []);

    
    function handleResponseFailure(error) {
        alertService.error(error)
    }

    function deleteProvider(id) {
        setProviders(providers.data.map(delete_response => {
            if (delete_response.id === id) { delete_response.isDeleting = true; }
            return x;
        }));
        providerService.delete(id).then(() => {
            setProviders(providers => providers.filter(x => x.id !== id));
        });
    }

    return (
        <div>
            <div className="mt-4 pt-2">
                <div className="ui two column grid" width="100%">
                    <div className="fourteen wide column column-addon">
                        <SearchBar handleSearch={handleSearch}/>
                    </div>
                    <div className="two wide column column-addon">
                        <RecordsPerPageSelection handleSelection={ handleRPP } />
                    </div>
                </div>
                <Table striped sortable>
                    <Table.Header>
                        <Table.HeaderCell
                            sorted={column == 'provider_name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'provider_name', data: providers})}
                        >
                            Provider Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'phone' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'phone', data: providers})}
                        >
                            Provider Phone
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'site_name' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'site_name', data: providers})}
                        >
                            Site Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column == 'email_address' ? direction : null}
                            onClick={() => handleSort({ type: 'CHANGE_SORT', column: 'email_address', data: providers})}
                        >
                            Email Address
                        </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                        {providers && providers.data.map(provider =>
                            <Table.Row key={provider.id}>
                                <Table.Cell selectable style={{display:'flex', alignItems:'center', valign:'middle', verticalAlign:"middle"}}>
                                    <Popup content={provider.status_id === "DISABLED" ? "Disabled" : "Enrolled"} trigger={provider.status_id === "DISABLED" ? <Icon style={{paddingLeft: '10px', paddingBottom: '19px'}}  name='minus circle' color='red'/>:<Icon style={{paddingLeft: '10px', paddingBottom: '19px'}} name='check circle' color='green'/>}></Popup>
                                    <Link to={`${path}/view/${provider.id}`}>{provider.provider_name}</Link></Table.Cell>
                                <Table.Cell>{!provider.phone.includes("-") && (provider.phone.length < 11) ? provider.phone.slice(0,3)+"-"+provider.phone.slice(3,6)+"-"+provider.phone.slice(6) : provider.phone}</Table.Cell>
                                <Table.Cell >{provider.facilities.map(facility => <Label basic key = {facility.id} style={{color:'#3F2A57',margin:'5px'}}>{facility.name}<Icon style={{paddingLeft:'10px'}}name='hospital outline' /></Label>)}</Table.Cell>
                                <Table.Cell>{provider.email_address}</Table.Cell>
                            </Table.Row>
                        )}
                        {!providers &&
                            <Table.Row>
                                <Table.Cell colSpan="4" className="text-center">
                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {providers && !providers.data.length &&
                            <Table.Row>
                                <Table.Cell colSpan="4" className="text-center">
                                    <div className="p-2">No Providers To Display</div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>

                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <Pagination className="mt-5 mb-5"
                        onPageChange={(event, data) => handlePagination(data.activePage-1)}
                        defaultActivePage={providers ? providers.page_number : 1}
                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={providers ? Math.ceil(providers.total / providers.record_per_page) : 0}
                    />
                </div>
            </div>
        </div>
    );
}

export { List };
