import React from 'react';
import { NavLink } from 'react-router-dom';

import ElementLogo from '../assets/main_logo_sm.png';

function Nav() {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark nav">
            <div className="navbar-nav ml-4">
                <img src={ElementLogo} className="scale down"/>
            </div>
            <div className="navbar-nav ml-auto">
                <h3 style={{color: '#ffffff'}}>Patient Management Portal</h3>
            </div>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <NavLink to="/logout" className="nav-item nav-link">Logout</NavLink>
                </li>
            </ul>
        </nav>
    );
}

function Banner() {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark nav">
            <div className="navbar-nav ml-4">
                <img src={ElementLogo} className="scale down"/>
            </div>
            <div className="navbar-nav ml-auto">
                <h3 style={{color: '#ffffff'}}>Patient Management Portal</h3>
            </div>
            <ul className="navbar-nav ml-auto"/>
        </nav>
    );
}

export { Banner };

export { Nav };
