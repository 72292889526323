import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import IdleTimer, { useIdleTimer } from 'react-idle-timer';
import {
  Tab
} from 'semantic-ui-react';

import { Nav, Alert, Banner } from '../_components';
import { Patients } from '../patients';
import { Facilities } from '../facilities';
import { Providers } from '../providers';
import { Requests } from '../requests';
import { Metrics } from '../metrics';
import { Administrators } from '../administrators';
import Login from '../session/Login';
import { Logout } from '../session/Logout';
import useToken from '../session/Token';
import { Session }  from '../session';
import { sessionService, alertService } from '../_services';
import IdleTimerModal from '../_components/IdleTimerModal';

function App() {
    const { pathname } = useLocation();
    const history = useHistory();
    const { token, setToken } = useToken();

    // Idle timer states
    const timeoutMinutes = 15;
    const [timeout, setTimeout] = useState(1000 * 60 * timeoutMinutes);
    const [showModal, setShowModal] = useState(false);
    const [isTimedOut, setIsTimedOut] = useState(false);
    const [idleTimer, setIdleTimer] = useState();


    let panes = []
    let role = sessionStorage.getItem("user_role");
    
    if (role == 'Es_administrator' || role == 'Es_customer_service' ) {
       panes = [
        {
          menuItem: 'Patients',
          render: () => <Redirect from="/:url*(/+)" to="/patients" component={Patients} />
        },
        {
          menuItem: 'Providers',
          render: () => <Redirect from="/:url*(/+)" to="/providers" component={Providers} />
        },
        {
          menuItem: 'Sites',
          render: () => <Redirect from="/:url*(/+)" to="/facilities" component={Facilities} />
        },
        {
          menuItem: 'Requests',
          render: () => <Redirect from="/:url*(/+)" to="/requests" component={Requests} />
        },
        {
          menuItem: 'Metrics',
          render: () =>  <Redirect from="/:url*(/+)" to="/metrics" component={Metrics} />
        },
        {
          menuItem: 'Administrators',
          render: () =>  <Redirect from="/:url*(/+)" to="/administrators" component={Administrators} />
        }
      ]
    } else {
       panes = [
        {
          menuItem: 'Patients',
          render: () => <Redirect from="/:url*(/+)" to="/patients" component={Patients} />
        },
        {
          menuItem: 'Providers',
          render: () => <Redirect from="/:url*(/+)" to="/providers" component={Providers} />
        },
        {
          menuItem: 'Sites',
          render: () => <Redirect from="/:url*(/+)" to="/facilities" component={Facilities} />
        },
        {
          menuItem: 'Requests',
          render: () => <Redirect from="/:url*(/+)" to="/requests" component={Requests} />
        }
      ]
    }


    // Idle timer functions
    const handleClose = () =>  {
      setShowModal(false);
    }
    const handleLogout = () =>  {
      setShowModal(false);
      setIsTimedOut(false);
      sessionService
          .delete()
          .then(() => {
            history.push("/login")
        }).catch(alertService.error)
    }
    const onAction = (e) => {
      setIsTimedOut(false);
    }
    const onActive = () => {
      setIsTimedOut(false);
    }
    const onIdle = () => {
      if (isTimedOut) {
        handleLogout();
      } else {
        setShowModal(true);
        idleTimer.reset();
        setIsTimedOut(true);
      }
    }

    if(!sessionStorage["token"]){
      return (
        <div className="app-container bg-light">
            <Banner></Banner>
            <Alert />
            <div className="container pt-4 pb-4">
              <Login setToken={setToken} />
            </div>
            <div style={{ position:'absolute', bottom:0, marginLeft: '15px'}}>Version: 2.01.000</div>
        </div>
      )
    }

    return (
      <div className="app-container bg-light">
        <Nav />
        <Alert />
        <IdleTimerModal
          showModal={showModal}
          handleClose={handleClose}
          handleLogout={handleLogout}
          timeoutInMins={timeoutMinutes}
        />
        <IdleTimer
          ref={ref => setIdleTimer(ref)}
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={timeout} />
        <div className="pl-4 pt-4 pb-4 pr-4">
          <div className="tabbar">
            <Tab menu={{ secondary: true, pointing: true}} panes={panes}/>
          </div>
        </div>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route exact path="/" component={Patients} />
          <Route path="/patients" component={Patients} />
          <Route path="/providers" component={Providers} />
          <Route path="/facilities" component={Facilities} />
          <Route path="/requests" component={Requests} />
          <Route path="/metrics" component={Metrics} />
          <Route path="/administrators" component={Administrators} />
          <Route path="/logout" component={Session} />
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    );
}

export { App };
