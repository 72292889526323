import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import './Login.css';
import { sessionService, alertService } from '../_services';

export default function Login() {
  const [passwordState, setPassState] = useState(true);
  const history = useHistory();
  const [password, setPassword] = useState();

  const validationSchema = Yup.object().shape({
      email: Yup.string()
          .required('Email is required'),
      password: Yup.string()
          .required('Password is required')
  });

   // functions to build form returned by useForm() hook
   const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
       resolver: yupResolver(validationSchema)
   });

  function createSession(data) {
      return sessionService.create(data)
          .then(session => {
            const role = session["user_role"];
            // Allow only valid roles: Admin, Study Manager and Customer Service
            if (role === "Es_administrator" || role === "Es_clinical_site_manager" || role === "Es_customer_service")
            {
              setToken(session["id_token"]);
              sessionStorage.setItem("first_name", session.first_name);
              sessionStorage.setItem("last_name", session.last_name);
              sessionStorage.setItem("refresh_token", session.refresh_token);
              sessionStorage.setItem("user_role", session.user_role);
              sessionStorage.setItem("record_per_page", 10);
              sessionStorage.setItem('historyView', JSON.stringify([]));
              
              history.push('/patients');
              alertService.success('Session added', { keepAfterRouteChange: true });
            }
            else
            {
              alertService.error("User role does not have permission to login!") 
            }
        })
        .catch((err) => {
            console.log("Error: ", err);
            alertService.error(err);
        });
    }

  function setToken(userToken) {
    sessionStorage.setItem('token', userToken);
  }

  return(
    <form onSubmit={handleSubmit(createSession)} onReset={reset}>
        <h1>Login</h1>
        <div className="form-row">
            <div className="form-group col-6">
                <label>Email</label>
                <input name="email" autocomplete='off' type="text" ref={register} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="form-group col">
              <label>
                Password
                { <em> {password}</em> }
              </label>
              <input name="password" readOnly={passwordState} autocomplete='off' type="password" ref={register} onFocus={()=>{setPassState(false)}} style={{}} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
              <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
        </div>
        <div className="form-group">
            <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Submit
            </button>
        </div>
    </form>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
