import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const materialService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};

function getAll(params) {
    return fetchWrapper.post(`${baseUrl}/materials/list`, params);
}

function getById(id) {
    return fetchWrapper.get(`${baseUrl}/material/get?id=${id}`)
}

function create(params) {
    delete params.confirm_password;
    return fetchWrapper.post(`${baseUrl}/materials`, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/materials/update?id=${id}`, params);
}

/****** TO BE IMPLEMENTED LATER ******/
// prefixed with underscored because delete is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}
