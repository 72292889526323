export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete
};

const baseUrl = process.env.REACT_APP_USER_MANAGER;

async function validate_token() {
    if (sessionStorage.getItem("token")) {
        await checkAccessToken();
    }
}

async function get(url) {
    await validate_token();
    
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        } 
    };
    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

async function post(url, body, authToken) {
    await validate_token();

    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        },
        body: JSON.stringify(body)
    };

    return await fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

async function put(url, body) {
    await validate_token();

    const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, body) {
    await validate_token();

    const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

// helper functions

async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            const error = (data.message) || (data.errors[0]);
            return Promise.reject(error);
        }

        return data;
    });
}

async function handleError(error) {
    if ( typeof error === 'object') {
        if (error.detail) {
            return Promise.reject(error.detail);
        }
    }
    return Promise.reject(error);
}

async function checkAccessToken() {
    let url = `${baseUrl}/validate_token`;
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("token")
        }
    }
    let response = await fetch(url, requestOptions);
    var error = null;
    if (!response.ok) {
        await response.text().then(text => {
            const data = text && JSON.parse(text);
            error = (data.message) || (data.errors[0]);
        });

        if (error && response.status === 401 && error.detail.includes("Token is Expired")) {
            await handleExpiredTokens();
        }
    }

    return await response;
}

async function handleExpiredTokens() {
    const url = `${baseUrl}/refresh_user_token`;
    const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem("refresh_token")
        },
        body: JSON.stringify({role: sessionStorage.getItem("user_role")})
    }
    let response = await fetch(url, requestOptions);
    if (response.ok) {
        await response.text().then(text => {
            const data = text && JSON.parse(text);
            sessionStorage.setItem("token", data.id_token);
        });
    } else {
        // Log out user
        sessionStorage.setItem("token", "");
    }

    return await response;
}
