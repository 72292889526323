import { fetchWrapper } from '../_helpers';

const baseUrl = process.env.REACT_APP_USER_MANAGER;

export const sessionService = {
    create,
    delete: _delete
};

function create(params) {
    return fetchWrapper.post(`${baseUrl}/auth/token`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete() {
    sessionStorage.clear()
    return fetchWrapper.delete(`${baseUrl}/logout`);
}
