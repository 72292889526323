import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
    Menu, Modal, Button, Form, Header, Checkbox, TextArea, Loader
} from 'semantic-ui-react';
import { alertService, patientService, providerService } from '../_services';
import { CSVLink} from 'react-csv';
import { toInteger } from 'lodash';

function SideBarMenu(props) {
    const page = props.page;
    const path = props.path;
    const mode = props.mode;
    const history = useHistory();
    const id = window.location.pathname.match(/\d+/g);
    const [activeItem, setActiveItem] = useState(`${page} List`);
    const [reasons, setReasons] = useState([]);
    const [open, setOpen] = useState(false);
    const [userEnrollmentStatus, setEnrollmentStatus] = useState("");
    const [downloadedPatients, setDownloadedPatients] = useState([]);
    const [downloadedProviders, setDownloadedProviders] = useState([]);
    const [downloadReady, setDownloadReady] = useState(false);
    const [downloadingReport, setDownloadReport] = useState(false);
    const csvLink = useRef();

    const headers = [
        { label: "Patient ID", key: "patient_id" },
        { label: "Prescribing Provider", key: "provider_name" },
        { label: "Date Enrolled", key: "enrolled_on" },
        { label: "Mobile App User", key: "is_mobile_user" },
        { label: "Site", key: "site" },
        { label: "Patient Enrollment Status", key: "enrollment_status" },
        { label: "Patient Active Device", key: "active_device" },
        { label: "Patient Device Assignment Date", key: "device_assigned_at" },
        { label: "Patient Inactive Device(s)", key: "inactive_devices" },        
        { label: "Therapy Device", key: "therapy_device" },
        { label: "Date of Generated Report ", key: "therapy_date" },
        { label: "Date of Therapy Recorded", key: "therapy_recorded_at" }
      ];

    const provider_headers = [
        { label: "First Name", key: "first_name"},
        { label: "Last Name", key: "last_name"},
        { label: "Phone Number", key: "phone_number"},
        { label: "Facility Name", key: "facility_name"},
      ];

    // Changes sidebar options per page
    const pageMap = {
        "ES Users": 'administrators',
        "Patient": "patients",
        "Provider": "providers",
        "Site": "facilities",
        "Request": "requests",
        "Metrics": "metrics"
    };

    const deactivationReasons = [
        "Study Completion",
        "Voluntary Withdrawl",
        "Pregnancy",
        "Study Non-compliance",
        "Adverse Event",
        "Disease Progression",
        "Newly developed/Identified Exclusion Criteria",
        "Subject Lost to Follow-Up",
        "Subject Death",
        "Other"
    ];

    const validationSchema = Yup.object().shape({
        patient_id: Yup.number(),
        deactivation_reason: Yup.array()
            .of(Yup.string())
            .min(1, "At least one reason is required"),
        notes: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            patient_id: 0,
            deactivation_reason: [],
            notes: "",
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            handleDeactivation(formData);
        }
    });

    const handleItemClick = (event, {name}) => {
        setActiveItem(name); 
    };

    const clearForm = () => {
        formik.setFieldValue("deactivation_reason", []);
        formik.setFieldValue("notes", "");
        setOpen(false);
    };

    const handleReasons = (data) => {
        if (data.checked) {
            reasons.push(data.label);
        } else {
            reasons.pop(data.label);
        }
        formik.setFieldValue("deactivation_reason", reasons);
    };

    const handleDeactivation = (requestData) => {
        switch (page) {
            case "Patient":
                return patientService.delete(requestData)
                .then((resp) => {
                    alertService.success(resp);
                    clearForm();
                    history.push('..');
                })
                .catch((err) => {
                    alertService.error(err);
                });
            case "Provider":
                return providerService.delete(requestData)
                .then((resp)=>{
                    alertService.success(resp);
                    history.push('..');
                })
                .catch((err)=>{
                    alertService.error(err);
                })
            case "Site":
                console.log("Not yet implemented");
            default:
                break;
        }
    };

    const handlePatientDownload = () => {
        setDownloadReport(true);
        patientService.downloadPatients()
        .then(x => {
            if (x) {
                x.patients.map(item => {
                    item.enrollment_status = ((item.enrollment_status === 'ENROLLED') ? 'Active' : 'Inactive')
                    item.is_mobile_user = ((item.is_mobile_user === true) ? 'Yes' : 'No')
                    item.device_status = ((item.device_status === true) ? 'Assigned' : 'Unassigned')
                });
            }
            setDownloadedPatients(x.patients);
            setDownloadReady(true);
            setDownloadReport(false);
        });
    };

    const handleProviderDeletion = (setOpen) => {
        let provider_id = window.location.pathname.match(/\d+/g);
        let del_params = {'provider_id':toInteger(provider_id)};
        let res = handleDeactivation(del_params);
        setOpen(false);
    }

    const handleProviderDownload = () => {
        providerService.downloadProviders()
        .then (x => {
            if (x) {
                setDownloadedProviders(x.providers);
                setDownloadReady(true);
            }
        });
    };

    useEffect(() => {
        if (csvLink && csvLink.current && downloadReady) {
            csvLink.current.link.click();
            setDownloadReady(false);
        }
    }, [downloadReady]);

    useEffect(() => {
        const item_id = window.location.pathname.match(/\d+/g);
        formik.setFieldValue("patient_id", item_id ? Number(item_id[0]) : null);
        const patient_enrollment_status = JSON.parse(sessionStorage.getItem("enrollment_statuses"));
        setEnrollmentStatus(item_id ? patient_enrollment_status[item_id[0]] : "");
    }, [window.location.pathname]);
    
    useEffect(() => {
        // Check for view mode
        if (mode === "view") {
            setActiveItem("View");
        }
    }, [mode]);
    
    return (
        <div className="side-bar-item pl-4 ml-4">
            <Menu secondary vertical>
                <div>
                    {props.page !== "Metrics" && <Menu.Item as={ Link } to={`/${pageMap[page]}`}
                    name={`${page} List`}
                    active={activeItem === `${page} List`}
                    onClick={handleItemClick}
                    />}
                    {(props.mode === "list" || props.mode === "add") &&
                        <Menu.Item as={ Link } to={`${path}/add`}
                        name={`Add ${page}`}
                        active={activeItem === `Add ${page}`}
                        onClick={handleItemClick}
                        />
                    }
                    {((props.mode === "list" || props.mode === "add") && props.page == "Patient") &&
                    <div>
                        <Loader active={downloadingReport}>Downloading {props.page}s</Loader>
                        <CSVLink
                        data={downloadedPatients}
                        filename='patient_extract.csv'
                        className='hidden'
                        headers= {headers}
                        target='_blank'
                        ref={csvLink}
                        />
                        <Menu.Item
                        name='Download Report'
                        active={activeItem === 'Download Report'}
                        onClick={handlePatientDownload}
                        icon='download'
                        /> 
                    </div>
        
                    }
                    {((props.mode === "list" || props.mode === "add") && props.page == "Provider") &&
                    <div>
                        <CSVLink
                        data={downloadedProviders}
                        filename='provider_extract.csv'
                        className='hidden'
                        headers= {provider_headers}
                        target='_blank'
                        ref={csvLink}
                        />
                        <Menu.Item
                        name='Download Report'
                        active={activeItem === 'Download Report'}
                        onClick={handleProviderDownload}
                        icon='download'
                        /> 
                    </div>
        
                    }
                    {(props.mode === "edit" || props.mode === "view") &&
                        <Menu.Item as={ Link } to={`${path}/view/${id}`}
                        name='View'
                        active={activeItem === 'View'}
                        onClick={handleItemClick}
                        /> 
                    }
                    {(props.mode === "edit" || props.mode === "view") && ( `${page}` !== 'Patient' || userEnrollmentStatus !== "DISENROLLED") &&
                        <Menu.Item as={ Link } to={`${path}/edit/${id}`}
                        name='Edit'
                        active={activeItem === 'Edit'}
                        onClick={handleItemClick}
                        />
                    }
                    {(props.page == "Metrics") && (props.mode == "device" ) &&
                        <Menu.Item as={ Link } to={`${path}`}
                        name='Device Metrics'
                        active={activeItem === 'Device Metrics'}
                        onClick={handleItemClick}
                        />
                    }
                   
                </div>
            </Menu>
            <div>
                {(props.mode === "edit" && page === "Patient" &&
                    <Modal
                        dimmer="inverted"
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        trigger={
                            <Menu secondary vertical className="side-bar-item pl-1 pr-1">
                                <Menu.Item
                                    name='Deactivate'
                                    active={activeItem === 'Deactivate'}
                                    onClick={() => setOpen(true)}
                                />
                            </Menu>
                        }
                    >
                        <div className="ml-5 pl-5 mt-5 pt-5">
                            <Header content="Indicate Reason for Deactivation"/>
                            <p className="required-field">Select one:</p>
                            {deactivationReasons.map(item =>
                                <Modal.Content className="pt-2 pb-2">
                                    <Checkbox 
                                        label={item}
                                        name="deactivation_reason"
                                        error={formik.errors.deactivation_reason}
                                        value={formik.values.deactivation_reason}
                                        onChange={(event, data) => handleReasons(data)}
                                    />
                                </Modal.Content>
                            )}
                            <p className="pt-4"><b>Notes</b></p>
                            <Form className="mr-5 pr-5">
                                <TextArea 
                                    placeholder="Optional"
                                    name="notes" 
                                    error={formik.errors.notes}
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}/>
                            </Form>
                            <Modal.Actions className="modal-action-buttons pr-5 mr-5 mt-5 mb-5">
                                <Button 
                                    color='red'
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting} 
                                >
                                    {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Deactivate
                                </Button>
                                <Button 
                                    secondary 
                                    onClick={clearForm}
                                >
                                    Cancel
                                </Button>
                            </Modal.Actions>
                        </div>
                    </Modal>
                )}
            </div>
            <div>
                {(props.mode === "edit" && page === "Provider" && 
                <Modal
                    dimmer="inverted"
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    trigger={
                        <Menu secondary vertical className="side-bar-item pl-1 pr-1">
                            <Menu.Item
                                name='Deactivate'
                                active={activeItem === 'Deactivate'}
                                onClick={() => setOpen(true)}
                            />
                        </Menu>
                    }
                >
                    <div className = "ml-5 pl-5 mt-5 pt-5">
                        <Header>Are you sure you want to deactivate this provider?</Header>
                        <Modal.Actions className="modal-action-buttons pr-5 mr-5 mt-5 mb-5">
                        <Button 
                            color='red'
                            type="submit"
                            onClick={()=>(handleProviderDeletion(setOpen))}
                        >
                            Deactivate
                        </Button>
                        <Button
                            secondary
                            onClick={()=>setOpen(false)}
                        >
                            Cancel 
                        </Button>
                        </Modal.Actions>
                    </div>
                </Modal>)}
            </div>
        </div>
    );
}

export default SideBarMenu;